<template>
  <div class="store-info">
    <ul class="list-unstyled">
      <li>店家營業時間：{{ store.open }} ~ {{ store.close }}</li>
      <li class="pb-2">地址：{{ store.address }}</li>
      <li :class="openedClass(open)">{{ open }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "storeInfo",
  props: {
    store: null
  },
  computed: {
    open: function() {
      let now = new Date();
      let time = now.getHours() * 60 + now.getMinutes();
      let openTime = this.store.open.split(":");
      let openNumber = parseInt(openTime[0]) * 60 + parseInt(openTime[1]);
      let closeTime = this.store.close.split(":");
      let closeNumber = parseInt(closeTime[0]) * 60 + parseInt(closeTime[1]);
      if (+closeTime[0] < +openTime[0]) {
        closeNumber += 24 * 60;
      }
      if (time > closeNumber || time < openNumber) {
        return "休息中";
      }
      return "營業中";
    }
  },
  methods: {
    openedClass: function(open) {
      if (open == "營業中") {
        return "text-success";
      } else {
        return "text-danger";
      }
    }
  }
};
</script>

<style scoped>
.store-info {
  padding: 20px 36px;
}
</style>
