<template>
  <div>
    <favorMomo v-if="favor" @next="close"></favorMomo>
    <div class="full-page overflow-auto">
      <div class="position-relative stage-content pb-3">
        <stageHeader :character="stageCharacter" :stageLocation="foodMission.stageLocation"></stageHeader>
        <img class="img-fluid" :src="'/img/food/' + foodMission.store + '.jpg'" alt="" />
        <stageMessageNoInput :data="foodMission.message"></stageMessageNoInput>
        <storeInfo :store="foodMission.storeInfo"></storeInfo>
        <!-- 打卡按鈕 -->
        <!-- <div class="unlock-block position-relative">
          <div class="unblock-inside d-flex position-relative">
            <a @click="checkPosition" class="d-flex align-items-center justify-content-center check-link">
              <div>
                <img class="check-food" src="/img/icon-food2.svg" alt="" />
                <div class="mt-2">小桃我幫你買！</div>
              </div>
            </a>
          </div>
          <div class="  py-2 d-flex justify-content-end position-relative border-top">
            <a role="button" @click="showInfoModal(1)">
              <svg class="btn-icon-24">
                <use xlink:href="#icon-intro" class="fill-blue"></use>
              </svg>
            </a>
          </div>
        </div> -->

        <img v-if="!showSlider" class="img-fluid" :src="'/img/food/' + foodMission.store + '2.jpg'" alt="" />
        <slider v-else :slides="foodMission.tofuImgSlide"></slider>
        <stageMessage
          :user="user"
          :stageCharacter="siteCharacter.name"
          :data="puzzle"
          :stage="puzzle.stage"
          :step="1"
          v-on:hintAdd="parseHint"
          @stageClear="stageClear"
        ></stageMessage>
        <div class="px-4  py-2 d-flex justify-content-end position-relative border-top">
          <a role="button" @click="showInfoModal(2)">
            <svg class="btn-icon-24">
              <use xlink:href="#icon-intro" class="fill-blue"></use>
            </svg>
          </a>
        </div>
      </div>
      <mainMenu></mainMenu>
    </div>
  </div>
</template>

<script>
import Avatar from "../Mixins/Avatar";
import CharacterFromVuex from "../Mixins/CharacterFromVuex";
import WebSocketMixin from "../Mixins/WebSocketMixin";
import UserFromVuex from "../Mixins/UserFromVuex";
import getDistance from "@/Mixins/getDistance";

import mainMenu from "@/views/part/mainMenu";
import slider from "@/views/part/slider";
import favorMomo from "./part/favorMomo";
import { mapActions, mapGetters } from "vuex";
import storeInfo from "@/views/part/storeInfo";
import stageHeader from "@/views/part/stageHeader";
import stageMessageNoInput from "@/views/part/stageMessageNoInput";
import stageMessage from "@/views/part/stageMessage";

import TeamFromVuex from "../Mixins/TeamFromVuex";
import ModalMixin from "../Mixins/ModalMixin";
import DeviceApiMixin from "../Mixins/DeviceApiMixin";

export default {
  name: "foodContainer",
  mixins: [
    getDistance,
    Avatar,
    CharacterFromVuex,
    WebSocketMixin,
    UserFromVuex,
    TeamFromVuex,
    ModalMixin,
    DeviceApiMixin
  ],
  components: {
    mainMenu,
    favorMomo,
    storeInfo,
    stageHeader,
    stageMessageNoInput,
    stageMessage,
    slider
  },
  props: {
    foodMission: {
      default: {}
    }
  },
  computed: {
    ...mapGetters("Character", ["getRoleByName"]),
    ...mapGetters("Stage", ["flags", "flag"]),
    ...mapGetters("Food", {
      foodFlags: "flags",
      foodFlag: "flag"
    }),

    stageCharacter() {
      return this.getRoleByName(this.siteCharacter.name);
    },
    showSlider() {
      if (this.foodMission.store !== "tofu") return false;
      return true;
    }
  },
  data() {
    return {
      favor: false,
      siteCharacter: {
        name: "小桃"
      },
      userCenter: [],
      puzzle: this.foodMission.puzzle,
      introContent: {
        1: "<p>到店家現場打卡，無需消費，按下打卡圖示便視同幫小桃買愛吃的食物，會增加好感度</p>",
        2: "<p>解開謎題。每個店家都有小桃關於美食的回憶，了解喜歡的人當然也會增加好感度啦！這部分不需到現場即可解題，只有最先解開謎題者會增加好感度。</p>"
      }
    };
  },
  methods: {
    ...mapActions("Stage", ["setFlag"]),
    ...mapActions("Food", {
      FoodSetFlag: "setFlag"
    }),

    ...mapActions("Favor", ["setWinnerLocal"]),
    close() {
      this.favor = false;
    },
    checkPosition: function() {
      if (navigator.geolocation) {
        let distance = this.getDistance(
          this.coords.latitude,
          this.coords.longitude,
          this.foodMission.checkPosition.position[0],
          this.foodMission.checkPosition.position[1],
          "M"
        );

        let near_enough = distance < this.foodMission.checkPosition.distance;
        if (near_enough) {
          if (!this.foodFlag(this.foodMission.store)) {
            this.FoodSetFlag({ key: this.foodMission.store, status: true });
            this.setWinnerLocal({
              winner: this.character,
              user: this.user
            });
            this.favor = true;
            console.log("food this.favor", this.favor);

            this.addScoreByRate({
              score: 2,
              characterName: this.character?.name,
              isMoveStage: false,
              no_hint: true,
              no_time: true
            });
          } else {
            this.showModal("你已經拿到分數了喔");
            // alert("你已經拿到分數了喔");
          }
        } else {
          // alert("要到達店家的位置才能買唷！");
          this.showModal("要到達店家的位置才能買唷！");
          //測試之後要刪掉
          // this.setFlag({ key: this.location.nextPhase, status: true });
        }
      } else {
        // alert("您的瀏覽器不支援地理定位");
        this.showModal("您的瀏覽器不支援地理定位");
      }
      // //

      //this.$emit('showIgStory', this.location.nextPhase)
    },
    parseHint() {
      this.puzzle.hintIndex++;
    },
    stageClear() {
      if (this.flag(this.foodMission.store)) {
        return;
      }
      this.setFlag({ key: this.foodMission.store, status: true });
      this.addScoreByRate({
        score: 5,
        characterName: this.character?.name,
        isMoveStage: false,
        no_hint: true,
        no_time: true
      });
      this.favor = true;
    },
    showInfoModal(index) {
      let intro = {
        content: this.introContent[index]
      };
      this.setInfoModal(intro);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/__var";
.unlock-block .unblock-inside a.d-flex {
  width: 100%;
}

.check-food {
  width: 40px;
  height: 34px;
}
.check-link {
  text-decoration: none;
  text-align: center;
}
strong {
  color: #4285f4;
}
a.hint-info {
  width: 20px;
  height: 20px;
  background: #fff;
  color: $info-color;
  border-radius: 50%;
  font-weight: bold;
}
</style>
