<template>
  <div>
    <foodContainer :foodMission="missionContent"></foodContainer>
  </div>
</template>

<script>
import StageFromMixin from "../Mixins/StageFromMixin";
import ModalMixin from "../Mixins/ModalMixin";
import foodContainer from "./foodContainer";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [StageFromMixin, ModalMixin],
  name: "foodTest",
  components: {
    foodContainer
  },
  props: ["kind"],
  computed: {
    ...mapGetters("User", ["intro_show_flag"]),
    missionContent: function() {
      let store = this.foodMission.find(elem => elem.store == this.kind);
      return store;
    }
  },
  data() {
    return {
      foodMission: [
        {
          store: "tofu",
          stageLocation: {
            time: "美食任務",
            location: "阿泉臭豆腐"
          },
          checkPosition: {
            position: [25.0455, 121.516304],
            distance: 30
          },
          message: [
            {
              name: "小桃",
              content:
                "每次在南陽街補習買晚餐的時候，我都有點糾結。因為……我超喜歡吃臭豆腐的！阿泉大概可以排我南陽街最愛的美食第一名吧！外酥內軟配上酸甜的台式泡菜，再加一點點辣椒真的好好吃啊！！可是那群成功男生，每次都嗆我吃完整個人變很臭，他們也不聞聞自己一身汗臭味(ﾒﾟДﾟ)ﾒ"
            }
          ],
          storeInfo: {
            open: "07:00",
            close: "23:00",
            address: "台北市中正區許昌街26-1號"
          },
          tofuImgSlide: {
            width: 600,
            height: 600,
            imgs: ["food/tofu2.jpg", "food/tofu3.jpg"]
          },
          puzzle: {
            unlockFlag: "tofu",
            stage: {
              stage_id: 11
            },
            defaultMsg: [
              {
                name: "小桃",
                content:
                  "今天在補習班胖子居然主動幫我買我最喜歡吃的阿泉臭豆腐，那群臭男生平時都喜歡拿這件事取笑我的，不知道今天是哪根筋不對？<br><br>" +
                  "一打開袋子我就覺得我是被整了吧！胖子居然用一堆臭豆腐拼出一個大愛心！" +
                  "天啊！我得把買臭豆腐的錢還給胖子才行！但……胖子到底買了幾塊臭豆腐才拼得出這麽大的愛心啊？"
              }
            ],
            answer: ["5", "五", "伍"],
            echoOfanswer: "對，真的是<strong>五</strong>塊臭豆腐耶！吃不完啦咩噗😥",
            hintIndex: 0,
            hint: [
              "我知道阿泉臭豆腐一塊臭豆腐會沿著對角線切成四個小三角形！",
              "這個愛心數起來應該有20塊小三角形臭豆腐吧……",
              { content: "下個提示很接近正確答案囉！再次點擊提示按鈕查看" },
              "嗯…總共有<strong>5</strong>塊臭豆腐…我記得小份的是3塊，大份的是4塊，怎麼湊出5塊的？胖子是把吃剩的臭豆腐包給我嗎"
            ]
          }
        },
        {
          store: "beef",
          stageLocation: {
            time: "美食任務",
            location: "劉山東牛肉麵"
          },
          checkPosition: {
            position: [25.045672, 121.51378],
            distance: 30
          },
          message: [
            {
              name: "小桃",
              content:
                "每個台灣人都有一家自己珍藏的牛肉麵店，而我的最愛就是劉山東！它坐落在北車附近不起眼的小巷弄裡，但總有絡繹不絕的老饕上門。帶著淡淡中藥味的清燉湯頭，上面撒著翠綠青蔥，配上入口即化的牛肉和嚼勁十足的粗麵條。我多想私藏它，但無奈米其林和我同樣有眼光<br>(๑•́ ₃ •̀๑)"
            }
          ],
          storeInfo: {
            open: "08:00",
            close: "20:00",
            address: "台北市中正區開封街一段14巷2號"
          },
          puzzle: {
            unlockFlag: "beef",
            stage: {
              stage_id: 72
            },
            defaultMsg: [
              {
                name: "小桃",
                content:
                  "我特別喜歡有嚼勁的麵條，劉山東的湯頭即使是紅燒也不嗆喉，深得我心😊<br><br>" +
                  "在K書中心讀累了，很需要熱湯療癒的時候就會想到劉山東！不過每次去排隊的人潮總是很多，如果沒朋友陪就會覺得排隊時間有點苦悶～今天讀書總是讀不進去，效率好差，特別想吃劉山東，還好有<strong>你</strong>說走就走，陪我排了很久的隊伍，也不怕穿著制服吃牛肉麵胸口會被濺上紅點，沒想到胸口沒事卻不小心沾到了袖口，紅點超明顯的！😂😂<br><br>" +
                  "隔壁桌的客人吃麵吃到眼鏡都起霧了哈哈哈哈哈哈！看來劉山東的魅力就是即使眼裡什麼都看不見，都還是得吃啊！😎"
              },
              {
                name: "？？",
                content:
                  "不客氣啦~~我也正好想吃啊XD 你別偷笑隔壁桌，如果我們繼續這樣天天K書，說不定以後被笑的就換成我們了!!!"
              }
            ],
            answer: "小彥",
            echoOfanswer: "總之，謝謝<strong>小彥</strong>！下次再一起去吃吧！😊",
            echoOfCharacter: "小桃",
            hintIndex: 0,
            hint: [
              "如果我們也有捲袖口的習慣，那就不會被噴到了，還好我的制服是綠色的，噴到比較沒那麼醒目～",
              "還好我們都沒近視不戴眼鏡，不然戴眼鏡吃熱湯麵多麻煩！",
              { content: "下個提示很接近正確答案囉！再次點擊提示按鈕查看" },
              "<strong>小彥</strong>老是穿著長袖白襯衫制服，不捲袖口，也不像赤木、白爛一樣把襯衫當外套穿，也不像胖子一樣近視戴眼鏡"
            ]
          }
        },
        {
          store: "douhua",
          stageLocation: {
            time: "美食任務",
            location: "城中豆花伯"
          },
          checkPosition: {
            position: [25.044248, 121.512319],
            distance: 30
          },
          message: [
            {
              name: "小桃",
              content:
                "藏在傳統市場中的小小豆花攤，是我夏日課後的小確幸。\n" +
                "綿密滑順的古早味手工豆花，配上花生、澆上糖水和滿滿碎冰 \n" +
                "只要一口就能把惱人的暑氣全數趕走！(*´▽`*)\n"
            }
          ],
          storeInfo: {
            open: "12:00",
            close: "19:00",
            address: "台北市中正區武昌街一段21號巷子內第二間"
          },
          puzzle: {
            unlockFlag: "douhua",
            stage: {
              stage_id: 31
            },
            defaultMsg: [
              {
                name: "小桃",
                content:
                  "豆花伯真的是我從小到大吃不膩的味道，從伯伯賣到第二代接手，傳統的小攤子都在這沒變過，從小學吃到現在，放學後也常和他們一起來吃！不過臭男生們真的是很皮，居然在玩食物，唸了一下他們，他們居然說等等會撿起來吃掉啦！😑<br><br>" +
                  "他們把配料排排站在桌上要我猜猜看<strong>中間那條直排</strong>什麼意思，說猜不到的話這次要我請客，傻眼🙄……"
              }
            ],
            answer: ["SWEET", "sweet", "Sweet"],
            echoOfanswer: "<strong>SWEET</strong>啊！還好我看了一下就看出來了～看著看著就又想來一碗豆花了😏",
            hintIndex: 0,
            hint: [
              "薏仁、紅豆、花生、芋圓看起來都好好吃唷！但是中間有個W到底是什麼意思嘛",
              "紅豆1、2、3、4、5、6、7…有七個，redbean也是七個字母耶？?",
              { content: "下個提示很接近正確答案囉！再次點擊提示按鈕查看" },
              "終於看出來了，直的這排正好是<strong>SWEET</strong>，呃，好吧我請客吧，還好豆花不貴😥"
            ]
          }
        },
        {
          store: "lautianlu",
          stageLocation: {
            time: "美食任務",
            location: "老天祿滷味"
          },
          checkPosition: {
            flag: "stage_6_food_1_clear",
            position: [25.044717, 121.507245],
            distance: 30
          },
          message: [
            {
              name: "小桃",
              content:
                "每次要去看電影的時候，都會特地去買包老天祿滷味來吃！鴨舌、鴨翅膀可以慢慢啃，很適合步調緩慢的愛情文藝片；百頁、豆干和米血則是看動作片時會買的，一口一口吃以免被驚險畫面嚇到，直接噴出嘴巴！( ˘•ω•˘ )"
            }
          ],
          storeInfo: {
            open: "09:30",
            close: "22:00",
            address: "台北市萬華區武昌街二段55號"
          },
          puzzle: {
            unlockFlag: "lautianlu",
            stage: {
              stage_id: 61
            },
            defaultMsg: [
              {
                name: "小桃",
                content:
                  "等會和他們約好了要看電影，我正要進老天祿買吃的就被花輪攔下了，硬塞給我一包老天祿滷味，我狐疑地往裡頭看，裡面放著一份豆干、一份雞爪、一份米血、還倒了些辣粉、鴨舌跟鴨胗裝在同一包、和一份雞脖。<br><br>" +
                  "接著花輪吞吞吐吐的跟我說「這是我的心意！」就先走了……奇怪了= =到底在幹嘛？花輪幹嘛有話不直接說，還要我猜謎？"
              }
            ],
            answer: ["ILIKEU", "ilikeu", "Ilikeu", "IlikeU", "ILikeU"],
            echoOfanswer: "<strong>I Like U</strong>…花輪這招花式告白不知道哪裡學來的！不過我對老天祿才是真愛啦😍",
            hintIndex: 0,
            hint: [
              "我實在是想不通，問了聰明的春哥，她看了看只說「訂購單上的格線好像才是重點」",
              "這些東西似乎在訂購單上有什麼共同處，裝在同一包的得一起看才行。",
              { content: "下個提示很接近正確答案囉！再次點擊提示按鈕查看" },
              "喔喔！看著品項欄旁邊的線，就是英文字母啦，倒辣粉正好是K，剛好是 <strong>ILIKEU</strong>…真受不了花輪，要不要裝沒猜出來呢"
            ]
          }
        },
        {
          store: "noodles",
          stageLocation: {
            time: "美食任務",
            location: "阿宗麵線"
          },
          checkPosition: {
            flag: "stage_4_food_2_clear",
            position: [25.043395, 121.507656],
            distance: 30
          },
          message: [
            {
              name: "小桃",
              content:
                "香噴噴的大腸麵線，加點辣椒、蒜醬、烏醋，真的是色香味俱全呢！ლ(´ڡ`ლ) 雖然沒有地方坐，但每次路過時聞到香味，還是忍不住會想吃"
            }
          ],
          storeInfo: {
            open: "09:30",
            close: "22:30",
            address: "台北市萬華區峨眉街8-1號"
          },
          puzzle: {
            unlockFlag: "noodles",
            stage: {
              stage_id: 62
            },
            defaultMsg: [
              {
                name: "小桃",
                content:
                  "那天大家來西門町逛街，經過聞到麵線香味就提議要吃一碗，因為店裡沒有座位，我們一行人就靠著牆邊站成一列，聽那群男生又在耍白痴、做一些無聊的蠢事，等春哥提醒我「趕快吃，麵線都要涼了」，我才拿起湯匙正準備舀一口……<br><br>" +
                  "突然發現，到底，是誰！<br>" +
                  "誰那麼可惡偷偷把香菜加到我的碗裡！😠<br><br>" +
                  "大家剛剛都沒有移動過位置，所以站在我旁邊的人應該是最可疑的🤔 <br><br>" +
                  "👦🏻胖子：我前後都沒有女生<br>" +
                  "👦🏻小彥：那天我是帶隊的，最先買好<br>" +
                  "👦🏻赤木：我擠在兩個女生之中<br>" +
                  "👦🏻白爛：我搶在死胖子前面<br>" +
                  "👩🏻小希：我跟著男生們走，在男生們之中<br>" +
                  "👩🏻春哥：我永遠在旁邊守護著小桃<br>" +
                  "👦🏻花輪：總是有一個人擋在我跟小桃之間<br>"
              }
            ],
            answer: "赤木",
            echoOfanswer: "<strong>赤木</strong>真的很幼稚耶！下次撒一大把糖進他的碗裡好了🙄",
            hintIndex: 0,
            hint: [
              "我站在正中間，小彥在第一個，胖子和花輪都不是站在我旁邊應該不是兇手",
              "小希站得遠也不是她，白爛滿可疑的，但他好像在胖子前面啊……？",
              { content: "下個提示很接近正確答案囉！再次點擊提示按鈕查看" },
              "春哥雖然站我旁邊，但她才不會做這種事！一定是<strong>赤木</strong>了！就他老是愛鬧我！臭赤木！！"
            ]
          }
        },
        {
          store: "olympiafoods",
          stageLocation: {
            time: "美食任務",
            location: "世運食品"
          },
          checkPosition: {
            flag: "stage_3_food_2_clear",
            position: [25.042911, 121.511559],
            distance: 30
          },
          message: [
            {
              name: "小桃",
              content:
                "如果放學後不太餓，或是不知道想吃什麼的時候，我就會去世運！各式各樣的港式點心、台式滷味，最適合什麼都想嚐一點的我！ <br/>(*///▽///*)"
            }
          ],
          storeInfo: {
            open: "08:30",
            close: "21:00",
            address: "台北市中正區博愛路101號"
          },
          puzzle: {
            unlockFlag: "olympiafoods",
            stage: {
              stage_id: 32
            },
            defaultMsg: [
              {
                name: "小桃",
                content:
                  "學校要校慶了，班會在討論我們班攤位要賣什麼東西，大家提了一堆意見 ，卻一點共識都沒有……一群人要討論出一個共同方向本來就是件困難的事吧😥<br><br>" +
                  "黑板上列的選項目前有「中式餐點、西式麵包、日式壽司、港式點心」，這些東西哪能通通混在一起賣啊？" +
                  "有同學就說，「不會啊！世運就都賣這些東西啊！」想想也是，乾脆去買世運把想到的品項通通都買回來吃吃看，再來看看共同喜好，投票決定要賣什麼好了！<br><br>" +
                  "吼！真是的！這個品項是誰列的啦！世運根本<strong>沒賣這項東西</strong>啊！😰"
              }
            ],
            answer: "咖椰吐司",
            echoOfanswer: "這個<strong>咖椰吐司</strong>可是新加坡小吃，我也很喜歡吃呢！可惜世運沒有賣啦！",
            hintIndex: 0,
            hint: [
              "世運賣很多品項，不過都沒有脫離「中式餐點、西式麵包、日式壽司、港式點心」這四大品項",
              "滷味、傳統糕點都是中式糕點，燒賣點心之類的就是港式點心……世運食品的官網也羅列了很多品項可以看",
              { content: "下個提示很接近正確答案囉！再次點擊提示按鈕查看" },
              "<strong>咖椰吐司</strong>明明就是新加坡的庶民小吃，是誰把它列進來啦……"
            ]
          }
        },
        {
          store: "pastry",
          stageLocation: {
            time: "美食任務",
            location: "明星西點麵包"
          },
          checkPosition: {
            flag: "stage_4_food_2_clear",
            position: [25.044137, 121.512701],
            distance: 30
          },
          message: [
            {
              name: "小桃",
              content:
                "老字號的高級西點麵包店，販售著一個獨門神奇軟糖，有點像Q彈又扎實的牛奶風味棉花糖，但吃起來不會太甜太膩，裡頭包著核桃，讓口感除了軟Q以外更加豐富，配著紅茶或咖啡一起吃，整個幸福到爆炸！！ (*´∀`)~♥"
            }
          ],
          storeInfo: {
            open: "08:30",
            close: "20:30",
            address: "台北市中正區武昌街一段7號"
          },
          puzzle: {
            unlockFlag: "pastry",
            stage: {
              stage_id: 42
            },
            defaultMsg: [
              {
                name: "小桃",
                content:
                  "西門的咖啡廳很多，但明星西點真的是去過就很難忘，小時候阿姨在這裡買俄羅斯軟糖給我吃過以後就一直念念不忘，這裡的俄羅斯料理都很特別，也許開始修俄文也是受到料理的啟蒙呢😊<br><br>" +
                  "你們知道明星咖啡廳的店名是由俄文翻成英文的嗎？英文一開始學草寫超困難的，不過一筆一劃慢慢練習就會寫得好啦！你們看得懂這些是什麼"
              }
            ],
            answer: ["KISS", "kiss", "Kiss"],
            echoOfanswer: "<strong>KISS</strong>💋 觀察力很敏銳呢！我從小很喜歡玩這種找小東西的遊戲～",
            hintIndex: 0,
            hint: [
              "店門口近年才重新裝潢過，門面很美，你們有空可以去看看呀！",
              "沒學過俄文看不懂？招牌是英文字母啦！應該不至於看不懂吧！再仔細看看就看懂了～",
              { content: "下個提示很接近正確答案囉！再次點擊提示按鈕查看" },
              "<strong>KISS</strong>！這幾個字母正好是K（中間角）、I（下方）、S（上面點）、S（下面點），很有趣吧！"
            ]
          }
        },
        {
          store: "pepperCake",
          stageLocation: {
            time: "美食任務",
            location: "福州世祖胡椒餅"
          },
          checkPosition: {
            flag: "stage_7_food_2_clear",
            position: [25.046001, 121.513318],
            distance: 30
          },
          message: [
            {
              name: "小桃",
              content:
                "每當趕著去補習班上課、沒時間坐下來慢慢吃飯的時候，我就會買這家胡椒餅，外皮香脆、肉餡鮮美，還有滿滿的肉汁，一大個胡椒餅不只滿足了我的胃，也滿足了我的嘴(๑´ڡ`๑)"
            }
          ],
          storeInfo: {
            open: "11:00",
            close: "21:00",
            address: "台北市中正區重慶南路一段13號"
          },
          puzzle: {
            unlockFlag: "pepperCake",
            stage: {
              stage_id: 71
            },
            defaultMsg: [
              {
                name: "小桃",
                content:
                  "最近的伙食費太凶了，零用錢都要不夠花了，只好到處搜集店家的優惠券，能省一點是一點！😢<br><br>" +
                  "不過今天雨實在太大了，我把傳單放在手提袋裡，沒想到濕成這樣都看不清楚了！好險折價券截角還在！不過上面寫說使用折價券要說出通關密語耶？<br><br>" +
                  "這旁邊五顏六色奇怪的圖騰是什麼意思啊？吼！怎麼辦啦～～解不出通關密語怎麼用折價券，快救救月底吃土的小桃！😭😭"
              }
            ],
            answer: [
              "PEPPERCAKE",
              "peppercake",
              "PEPPER CAKE",
              "pepper cake",
              "Peppercake",
              "Pepper cake",
              "Pepper Cake"
            ],
            echoOfanswer: "哇！<strong>PEPPER CAKE</strong>！太好了～終於解出通關密語了！謝謝你～～",
            hintIndex: 0,
            hint: [
              "由上到下、由左至右應該是這樣讀的",
              "我發現同個顏色的區塊好像長得一樣",
              { content: "下個提示很接近正確答案囉！再次點擊提示按鈕查看" },
              "喔喔！<strong>PEPPER CAKE</strong>！這是胡椒餅的傳單呀！我都忘了！"
            ]
          }
        },
        {
          store: "plum",
          stageLocation: {
            time: "美食任務",
            location: "公園號酸梅湯"
          },
          checkPosition: {
            flag: "stage_4_food_1_clear",
            position: [25.042332, 121.514013],
            distance: 30
          },
          message: [
            {
              name: "小桃",
              content:
                "下課往北車走去補習的路上，偶爾會經過這家老牌酸梅湯，它除了有著完美的酸甜平衡，更有股淡淡的桂花香，喝著冰涼的酸梅湯在公園裡散散步，是一大享受<br>(๑´ㅂ`๑) "
            }
          ],
          storeInfo: {
            open: "10:30",
            close: "20:00",
            address: "台北市中正區衡陽路2號"
          },
          puzzle: {
            unlockFlag: "plum",
            stage: {
              stage_id: 41
            },
            defaultMsg: [
              {
                name: "小桃",
                content:
                  "這家公園號酸梅湯真的開很久很久了～～！爸爸媽媽年輕時常來公園約會，每次都會來這裡買杯酸梅湯喝，公園號見證了他們的愛情開花結果，我小時候也常常被他們帶來這裡，不過小時候的我不大喜歡喝酸梅湯，相比之下還是比較喜歡吃冰淇淋！公園號的冰淇淋有三種口味，不同顏色裝在同一個碗裡很繽紛，好療癒！<br><br>" +
                  "我給每個顏色都起了一個代號：「1、1234567、7」" +
                  "你們能猜到3,4,1是什麼意思嗎？是我很喜歡的一個<strong>男歌手</strong>！"
              }
            ],
            answer: ["王力宏"],
            echoOfanswer:
              "對～是<strong>王力宏</strong>！雖然他最近沒什麼新作品，不過他的歌曲都很經典喔！如春雨裡洗過的太陽，明亮、清淨、溫暖，很療癒人心。",
            hintIndex: 0,
            hint: [
              "冰淇淋三種口味分別是紅豆，牛奶，芋頭，我都很喜歡吃耶！",
              "在公園散步遇上下雨的時候，都會來公園號躲雨，雨過天晴後經由白色光折射反射出來的彩虹真的是很驚艷，七道不同顏色的虹和冰淇淋一樣繽紛",
              { content: "下個提示很接近正確答案囉！再次點擊提示按鈕查看" },
              "黃綠紅…我最喜歡的歌手是<strong>王力宏</strong>啦！他的歌很好聽喔！"
            ]
          }
        },
        {
          store: "porkrice",
          stageLocation: {
            time: "美食任務",
            location: "金峰滷肉飯"
          },
          checkPosition: {
            flag: "stage_2_food_2_clear",
            position: [25.032116, 121.518581],
            distance: 30
          },
          message: [
            {
              name: "小桃",
              content:
                "有時候啦啦隊練到很晚才結束，真的餓到不行的時候，我們就會來金峰，點碗香噴噴的滷肉飯，滷肉肥瘦有度又入口即化，吃進嘴裡滿滿的膠質和Q彈白飯拌在一起，真是大大的滿足！罪惡感這種事就…..多點盤燙青菜安慰自己很健康囉～ʕ•͡ᴥ•ʔ"
            }
          ],
          storeInfo: {
            open: "09:00",
            close: "01:00",
            address: "台北市中正區羅斯福路一段10號"
          },
          puzzle: {
            unlockFlag: "porkrice",
            stage: {
              stage_id: 22
            },
            defaultMsg: [
              {
                name: "小桃",
                content:
                  "這家金峰滷肉飯一開始也不是我挖掘到的店家，有次我在中正紀念堂練啦啦隊動作的時候，大家不約而同的來探我的班，可能怕我餓著了，所有人都各自帶了美食來，我就是在當時吃到金鋒滷肉飯的，覺得練好久練不會的低潮心情忽然一吃而散！<br><br>" +
                  "不過那次到底買金峰滷肉飯來探班的到底是<strong>誰</strong>呀？你們還記得嗎？"
              }
            ],
            answer: "小彥",
            echoOfanswer: "對對對，是<stromg>小彥</stromg>啦！練舞練到累了來一碗滷肉飯超療癒的！😋",
            hintIndex: 0,
            hint: [
              "記得當時我們在中山堂一起跳舞還battle誰比較厲害那次嗎？真的很好笑耶！",
              "小希說她有印象，她和春哥在中正紀念堂站五號出口前遇到赤木，他帶了飲料來，在那之前其他人就已經買好其他鹹食了",
              { content: "下個提示很接近正確答案囉！再次點擊提示按鈕查看" },
              "啊，是<stromg>小彥</stromg>啦！帶金峰滷肉飯來探班也就他了，不過還好有他，我那天都快餓死了！"
            ]
          }
        },
        {
          store: "starfruit",
          stageLocation: {
            time: "美食任務",
            location: "成都楊桃冰"
          },
          checkPosition: {
            flag: "stage_6_food_3_clear",
            position: [25.04262, 121.507874],
            distance: 30
          },
          message: [
            {
              name: "小桃",
              content:
                " 台灣古早味的楊桃汁一直都是我的最愛，除了酸甜以外，最讓人上癮的其實是那股淡淡的鹹味！楊桃湯吃得到果肉很適合坐下來慢慢喝，而楊桃冰鋪滿碎冰，很消暑，最適合在夏天點一杯邊喝邊逛街了！"
            }
          ],
          storeInfo: {
            open: "13:00",
            close: "22:30",
            address: "台北市萬華區成都路3號"
          },
          puzzle: {
            unlockFlag: "starfruit",
            stage: {
              stage_id: 63
            },
            defaultMsg: [
              {
                name: "小桃",
                content:
                  "我實在是太愛喝楊桃汁了！每次路過都會買杯小杯楊桃汁來喝，所以大家就幫我取了外號叫小桃。<br><br>" +
                  "可是每當我跟別人解釋外號的由來時，聽到的人都會忍不住想唱一首歌，每次聽到熟悉的旋律，我只能笑笑的看著他們，跟著哼唱下一句……😅<br><br>" +
                  "對，就是<strong>那首歌</strong>！好了，可以了，不用唱出來！我不想聽！！😒"
              }
            ],
            answer: "用心良苦",
            echoOfanswer: "對！是張宇的<strong>用心良苦</strong>…你怎麼也知道啊😅",
            hintIndex: 0,
            hint: [
              "其實我覺得聽起來不像啊！都是那些人發音不標準啦！",
              "你沒想到？沒關係，那代表你應該滿年輕的吧，這首歌也有點年代了……",
              { content: "下個提示很接近正確答案囉！再次點擊提示按鈕查看" },
              "還是不知道？好吧，我唱！……妳說妳小楊桃，偏偏注定要落腳……對啦，是張宇的<strong>用心良苦</strong>…"
            ]
          }
        },
        {
          store: "xiaolongbao",
          stageLocation: {
            time: "美食任務",
            location: "杭州小籠湯包"
          },
          checkPosition: {
            flag: "stage_2_food_1_clear",
            position: [25.034091, 121.523619],
            distance: 30
          },
          message: [
            {
              name: "小桃",
              content:
                "當我們有什麼事情值得慶祝的時候，大家會一起去吃杭州小籠湯包當作小慶功宴，比如吉他成發、球隊晉級……等等，它沒有鼎泰豐那麼貴，但對高中生來說還是只能偶一為之的大餐了；湯包飽滿的內餡，香噴噴、熱呼呼的湯汁，非常鮮嫩可口，很受外地遊客歡迎呢！ (๑•̀ㅂ•́)و"
            }
          ],
          storeInfo: {
            open: "11:00",
            close: "22:00",
            address: "台北市大安區杭州南路二段19號"
          },
          puzzle: {
            unlockFlag: "xiaolongbao",
            stage: {
              stage_id: 21
            },
            defaultMsg: [
              {
                name: "小桃",
                content:
                  "先前班上的交換學生希望我推薦給她特別的台灣小吃，我就想到這家杭州小籠湯包，可能是烹調方式的不同，外國人似乎很少看過蒸菜，看到熱氣蒸騰的湯包連著蒸籠被端上桌時，一臉驚訝的樣子，一直問這是什麼？甚至懷疑蒸籠底下是不是放著乾冰才會一直冒煙，一吃完就把蒸籠翻來翻去在檢查有沒有什麼魔術機關！白爛看到也起了興致就喊「別動！」，然後就說要教交換學生中文。<br><br>" +
                  "要她看著這兩個蒸籠猜<strong>一句成語</strong>……交換學生中文雖好卻也沒到成語能信手拈來的地步呀😓" +
                  "她向我投以求救的目光，但我怎麼也看不懂呢？🤔"
              }
            ],
            answer: ["直搗黃龍", "直倒黃籠"],
            echoOfanswer: "哇，白爛出這個<strong>直搗黃龍</strong>居然有人答對！太厲害了",
            hintIndex: 0,
            hint: [
              "蒸籠一個是直立的，一個是倒扣著的",
              "這兩個蒸籠好像顏色特別黃",
              { content: "下個提示很接近正確答案囉！再次點擊提示按鈕查看" },
              "原來是<strong>直搗黃龍</strong>，這太難了吧😥"
            ]
          }
        }
      ]
    };
  },
  mounted() {
    if (this.intro_show_flag["food_mission"]) {
      let intro = {
        title: "支線 美食任務",
        content:
          "<p>美食任務的謎題不需要到店家位置也可以進行解謎，不進行也不會影響主線，可視時間斟酌遊玩，為節省時間和體力，建議有興趣品嚐的美食再前往</span></p>"
      };
      this.setInfoModal(intro);
      this.setIntroHide({ flag: "food_mission" });
    }
    // let store=this.foodMission.find(elem=>elem.store==this.kind);
    // console.log(store)
  },
  methods: {
    ...mapActions("User", ["setIntroHide"])
  }
};
</script>

<style scoped></style>
